<template>
  <div class="device-control">
    <div class="container-fluid py-4">
      <h2 class="h4 fw-medium mb-4">{{ $t("__deviceManagement") }}</h2>
      <div class="d-flex flex-wrap flex-md-nowrap align-items-center justify-content-start justify-content-md-between">
        <button
          v-show="!isShowSelected"
          @click="isShowSelected = !isShowSelected"
          class="me-2 fs-5 btn btn-primary px-2 rounded-circle lh-sm mb-2"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :title="$t('__select')"
        >
          <i class="bi bi-check2-square"></i>
        </button>
        <button
          v-show="isShowSelected"
          @click="isShowSelected = !isShowSelected"
          class="me-2 fs-5 btn btn-link px-2 rounded-circle lh-sm mb-2"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :title="$t('__closeSelect')"
        >
          <i class="bi bi-arrow-left"></i>
        </button>
        <button
          v-show="isShowSelected"
          @click="selectAllRows"
          class="me-2 fs-5 btn btn-primary px-2 rounded-circle lh-sm mb-2"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :title="$t('__allSelect')"
        >
          <i class="bi bi-check-all"></i>
        </button>
        <button
          v-show="isShowSelected && selected.length"
          @click="batchTask"
          class="me-2 btn btn-primary px-2 rounded-circle lh-sm mb-2"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :title="$t('__batchCommand')"
        >
          <i class="bi bi-terminal fs-5"></i>
        </button>
        <button
          v-show="isShowSelected && selected.length"
          @click="clearSelected"
          class="me-2 fs-5 btn btn-outline-primary px-2 rounded-circle lh-sm mb-2"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :title="$t('__clearAll')"
        >
          <i class="bi bi-x"></i>
        </button>
        <div class="device-select me-2 mb-2">
          <select v-model="filterType" class="form-select rounded-pill me-2">
            <option :value="null">{{ $t("__all") }}</option>
            <option :value="1">
              {{ $t("__bound") + $t("__agency") }}
            </option>
            <option :value="0">
              {{ $t("__unbound") + $t("__agency") }}
            </option>
          </select>
        </div>
        <div v-if="filterType !== 0" class="d-flex align-items-center me-2 mb-2">
          <div class="device-report-select">
            <select v-model="searchAgency" class="form-select rounded-pill me-2">
              <option :value="null">{{ $t("__all") + " ( " + $t('__agencyName') + ' / ' + $t('__codeName') + " )"}}</option>
              <option v-for="item in allAgencys" :value="item.id" :key="'agent'+ item.id">
                {{ item.name + ' / ' + item.codename }}
              </option>
            </select>
          </div>
        </div>
        <div class="me-2 mb-2">
          <form @submit.prevent="searchDevice">
            <div class="input-group rounded-pill">
              <input
                v-model="search"
                type="text"
                class="form-control rounded-pill"
                :placeholder="$t('__searchDeviceSerialId')"
                aria-label="search"
                aria-describedby="search"
              />
              <button
                type="submit"
                class="btn btn-link px-2 text-dark"
                id="search"
              >
                <i class="bi bi-search"></i>
              </button>
              <button
                v-if="filter"
                type="button"
                class="btn btn-primary px-3 ms-1 rounded-pill"
                id="clearSearch"
                @click="clearSearch"
              >
                {{ $t("__clearSearch") }}
              </button>
            </div>
          </form>
        </div>
        <div class="me-2 me-auto mb-2">
          <form @submit.prevent="searchAgencyByName">
            <div class="input-group rounded-pill">
              <input
                v-model="searchAgencyKeyword"
                type="text"
                class="form-control rounded-pill"
                :placeholder="$t('__searchAgencyName')"
                aria-label="search"
                aria-describedby="search"
              />
              <button
                type="submit"
                class="btn btn-link px-2 text-dark"
                id="search"
              >
                <i class="bi bi-search"></i>
              </button>
              <button
                v-if="searchAgencyKeyword"
                type="button"
                class="btn btn-primary px-3 ms-1 rounded-pill"
                id="clearSearch"
                @click="clearSearch"
              >
                {{ $t("__clearSearch") }}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <b-table
            :responsive="$store.state.windowWidth > $store.state.breakpoints.sm"
            :stacked="$store.state.windowWidth <= $store.state.breakpoints.sm"
            striped
            borderless
            no-provider-filtering
            head-variant="light"
            selectable
            select-mode="multi"
            :items="filterItem"
            :sort-desc="sortDesc"
            :sort-by="sortBy"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            @row-selected="onRowSelected"
            ref="selectableTable"
          >
            <template v-slot:head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template v-slot:cell(selected)="{ rowSelected }">
              <input
                :checked="rowSelected"
                class="form-check-input"
                type="checkbox"
                disabled
              />
            </template>
            <template v-slot:cell(agency)="data">
              {{  data.item.agency_id ? data.item.agency.name + " / " + data.item.agency.codename : '' }}
            </template>
            <template v-slot:cell(agent)="data">
              <div v-if="isSystemManage && data.item.manager_id !== userInfo.id">
              {{ data.item.manager_id ? data.item.manager.name + " / " + data.item.manager.username : '' }}
              </div>
            </template>
            <template v-slot:cell(active)="data">
              <div class="d-flex">
                <button
                  @click="taskHistory(data.item.id)"
                  type="button"
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__deviceCommandRecord')"
                >
                  <i class="bi bi-filter-square fs-5"></i>
                </button>
                <button
                  @click="task(data.item.id)"
                  type="button"
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__command')"
                >
                  <i class="bi bi-terminal-fill fs-5"></i>
                </button>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row align-items-center mt-3" v-if="totalRows">
        <div class="col-sm col-12 text-right text-sm-left">
          {{ $t('__paginationInfo', {
            startNub: currentPage == 1 ? 1 : perPage * (currentPage - 1) + 1,
            entNub: currentPage == 1
              ? totalRows > perPage
                ? perPage
                : totalRows
              : perPage * (currentPage - 1) + perPage > totalRows
              ? totalRows
              : perPage * (currentPage - 1) + perPage ,
            sum: totalRows
            })
          }}
        </div>
        <div class="col-sm col-12">
        <select class="form-select rounded-pill me-2  mb-2" v-model="perPage">
            <option :value="10">
              {{ '顯示數量／頁：10' }}
            </option>
            <option :value="25">
              {{ '顯示數量／頁：25' }}
            </option>
            <option :value="50">
              {{ '顯示數量／頁：50' }}
            </option>
          </select>
        </div>
        <div class="col-sm col-12">
          <b-pagination
            v-if="totalRows > perPage"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            class="mb-sm-0 mb-3 mt-sm-0 mt-3"
            @page-click="clickPagination"
          ></b-pagination>
        </div>
      </div>
    </div>
    <div class="modal fade cms-style" tabindex="-1" id="task" ref="taskModal">
      <div
        class="modal-dialog"
      >
        <div class="modal-content">
          <form @submit.prevent="submitForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium">
                {{ isBatchTask ? $t("__batchCommand") : modalData.serial_id + $t("__device") }}
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="text-center mb-3">
                <div class="custom-tab-check d-inline-flex">
                  <div class="form-check">
                    <input
                      v-model="modalData.task_type"
                      class="form-check-input"
                      type="radio"
                      name="task_typen"
                      value="system-management"
                      id="systemManagement"
                    />
                    <label
                      class="form-check-label fw-medium"
                      for="systemManagement"
                      >System Management</label
                    >
                  </div>
                  <div class="form-check">
                    <input
                      v-model="modalData.task_type"
                      class="form-check-input"
                      type="radio"
                      name="task_typen"
                      value="parameter"
                      id="parameter"
                    />
                    <label class="form-check-label fw-medium" for="parameter"
                      >Parameter</label
                    >
                  </div>
                </div>
              </div>
              <div v-if="modalData.task_type === 'system-management'">
                <div class="mb-3">
                  <label for="command" class="form-label fw-medium required"
                    >Command</label
                  >
                  <select
                    v-model="modalData.system_management.command"
                    class="form-select"
                    aria-label="Default select example"
                    :required="modalData.task_type === 'system-management'"
                  >
                    <option :value="null">
                      {{ $t("__openThisSelectMenu") }}
                    </option>
                    <option value="00">00: Reboot</option>
                    <option value="01">01: Firmware update instruction</option>
                    <option value="02">02: Raw data manual upload</option>
                    <option value="03">03: Notification&Heartbeat data manual upload</option>
                    <option value="04">04: Raw data upload switch</option>
                    <option value="05">05: Log file upload</option>
                    <option value="06">06: Manual time alignment</option>
                    <option value="07">07: Send WIFI Info to server</option>
                    <option value="08">08: Send Parameter Info to server</option>
                    <option value="09">
                      09: set file server IP/URL
                    </option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="system_management-parameter" class="form-label fw-medium"
                    >Parameter</label
                  >
                  <input
                    v-model="modalData.system_management.parameter"
                    type="text"
                    class="form-control"
                    id="system_management-parameter"
                  />
                </div>
              </div>
              <div v-else>
                <label for="parameter_json" class="form-label fw-medium required"
                  >Parameter</label
                >
                <textarea
                  v-model="modalData.parameter_json.parameter_json"
                  class="form-control"
                  :class="{
                    'is-invalid': validate && validate.msg
                  }"
                  rows="7"
                  id="parameter_json"
                  :required="modalData.task_type !== 'system-management'">
                </textarea>
                <div class="invalid-feedback" v-if="validate && validate.msg" style="white-space: pre-line;">
                  {{
                    JSON.stringify(validate.msg, null, " ").replace(/{|}|\\/g, "")
                  }}
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-outline-secondary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t("__cancel") }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t("__send") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip, Modal } from 'bootstrap'
import i18n from '@/lang/lang.js'
import { agency } from '@/http/api/agency.js'
import { device } from '@/http/api/device.js'
import { deviceControl } from '@/http/api/deviceControl.js'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'DeviceControl',
  data () {
    return {
      sortBy: 'serial_id',
      sortDesc: false,
      allAgencys: null,
      items: null,
      filterItem: null,
      filter: null,
      filterOn: ['serial_id', 'agency'],
      filterType: null,
      search: null,
      searchAgency: null,
      isBatchTask: false,
      isShowSelected: false,
      selected: [],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      bootstrap_tooltip: null,
      bootstrap_modal: null,
      taskModalId: null,
      modalData: {
        serial_id: null,
        task_type: 'system-management', // system-management parameter
        system_management: {
          command: null,
          parameter: null
        },
        parameter_json: {
          parameter_json: null
        }
      },
      validate: null,
      clickPaginationValue: 1,
      searchAgencyKeyword: null
    }
  },
  computed: {
    ...mapState(['token', 'userInfo']),
    fields () {
      return [
        {
          key: this.isShowSelected ? 'selected' : null,
          label: i18n.t('__batchCommand'),
          class: 'align-middle text-center',
          sortable: false
        },
        {
          key: 'serial_id',
          label: i18n.t('__deviceId'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'agency',
          label: i18n.t('__agencyName') + ' / ' + i18n.t('__codeName'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'agent',
          label: i18n.t('__agentName') + ' / ' + i18n.t('__account'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'boot_at',
          label: i18n.t('__bootTime'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'version',
          label: i18n.t('__versionNumber'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: this.isShowSelected ? null : 'active',
          label: i18n.t('__active'),
          tdClass: 'align-middle',
          class: 'align-middle',
          sortable: false
        }
      ]
    },
    permissions () {
      return this.userInfo.permissions
    },
    isSystemManage () {
      let systemManage = false
      if (!this.permissions) return false
      this.permissions.forEach((item) => {
        if (item.name === 'system-manage') {
          systemManage = true
        }
      })
      return systemManage
    },
    routeQuery () {
      const query = {}
      if (this.clickPaginationValue) {
        query.page = this.clickPaginationValue
      }
      if (this.filter) {
        query.search = this.filter
      }
      return query
    }
  },
  watch: {
    routeQuery (data) {
      if (Number(this.currentPage) !== Number(this.clickPaginationValue) || this.$route.query.search !== this.filter) {
        this.$router.push({ query: data }).catch(err => { console.log(err) })
      }
    },
    isShowSelected (data) {
      this.hideTooltip()
      this.initBootstrapTooltip()
      if (!data) {
        this.clearSelected()
      }
    },
    modalData: {
      handler: function () {
        this.validate = null
      },
      deep: true
    },
    items (data) {
      if (data) {
        const vm = this
        vm.filterItem = vm.items
        vm.initBootstrapTooltip()
      }
    },
    filterItem (data) {
      const vm = this
      if (data) {
        vm.totalRows = data.length
      } else {
        vm.totalRows = 0
      }
      vm.currentPage = vm.totalRows > vm.currentPage ? vm.currentPage : 1
    },
    filterType (data) {
      switch (data) {
        case null:
          if (this.searchAgency) {
            this.filterItem = this.items.filter(item => item.agency_id && this.searchAgency === item.agency_id)
          } else {
            this.filterItem = this.items
          }
          break
        case 0:
          this.filterItem = this.items.filter(item => !item.agency_id)
          this.searchAgency = null
          break
        case 1:
          this.filterItem = this.items.filter(item => {
            if (this.searchAgency) {
              return item.agency_id && this.searchAgency === item.agency_id
            } else {
              return item.agency_id
            }
          })
          break
      }
    },
    searchAgency (data) {
      if (data) {
        this.filterItem = this.items.filter(item => data === item.agency_id)
      } else {
        this.filterItem = this.items
      }
    }
  },
  methods: {
    ...mapMutations(['Loading', 'Loaded', 'AlertsInfo', 'Alerted']),
    clickPagination (bvEvent, page) {
      this.clickPaginationValue = page
    },
    selectAllRows () {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected () {
      this.$refs.selectableTable.clearSelected()
    },
    onRowSelected (items) {
      this.selected = items
    },
    getDevice () {
      const vm = this
      vm.Loading()
      device
        .get('', vm.token)
        .then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.items = res.data.data
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
          }
          vm.Loaded()
        })
        .catch((err) => {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: err
          })
          vm.Alerted()
          vm.Loaded()
        })
    },
    getAllAgency () {
      const vm = this
      agency.get('', this.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.allAgencys = res.data.data
        }
      })
    },
    searchDevice () {
      this.filter = this.search
    },
    searchAgencyByName () {
      this.filter = this.searchAgencyKeyword;
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = (this.$route.query.page && this.totalRows > this.$route.query.page) ? this.$route.query.page : 1
    },
    clearSearch () {
      this.filter = null
      this.search = null
      this.searchAgencyKeyword = null
    },
    initBootstrapTooltip () {
      this.$nextTick(function () {
        this.bootstrap_tooltip = [].slice
          .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          .map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
          })
        document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(ele => {
          ele.addEventListener('shown.bs.tooltip', function () {
            setTimeout(() => {
              Tooltip.getInstance(ele).hide()
            }, 600)
          })
        })
      })
    },
    hideTooltip () {
      if (this.bootstrap_tooltip && this.bootstrap_tooltip.length) {
        this.bootstrap_tooltip.forEach((tooltip) => {
          tooltip.hide()
        })
      }
    },
    resetTask () {
      this.modalData.serial_id = null
      this.modalData.task_type = 'system-management'
      this.modalData.system_management.command = null
      this.modalData.system_management.parameter = null
      this.modalData.parameter_json.parameter_json = null
    },
    batchTask () {
      const vm = this
      vm.isBatchTask = true
      vm.taskModalId = []
      vm.selected.forEach((item) => {
        vm.taskModalId.push(item.id)
      })
      vm.bootstrap_modal = new Modal(vm.$refs.taskModal)
      vm.$nextTick(function () {
        vm.bootstrap_modal.show()
      })
    },
    task (id) {
      const vm = this
      vm.taskModalId = id
      vm.resetTask()
      vm.items.forEach((item) => {
        if (item.id === id) {
          vm.modalData.serial_id = item.serial_id
        }
      })
      vm.bootstrap_modal = new Modal(vm.$refs.taskModal)
      vm.$nextTick(function () {
        vm.bootstrap_modal.show()
      })
    },
    taskHistory (id) {
      const vm = this
      vm.$router.push({ name: 'DeviceControlHistory', query: { id: id } })
    },
    submitForm () {
      const vm = this
      if (vm.isBatchTask) {
        deviceControl
          .batchCommand(
            {
              device_ids: vm.taskModalId,
              task_type: vm.modalData.task_type,
              data:
                vm.modalData.task_type === 'system-management'
                  ? vm.modalData.system_management
                  : vm.modalData.parameter_json
            },
            vm.token
          )
          .then((res) => {
            if (res.status <= 201 && res.data.status === 'success') {
              // vm.getDevice()
              vm.AlertsInfo({
                state: 'success',
                title: i18n.t('__success'),
                info: i18n.t('__sentOrder')
              })
              vm.bootstrap_modal.hide()
            } else {
              vm.validate = res.data.validate
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg.toString()
              })
            }
            vm.Loaded()
            vm.Alerted()
          })
      } else {
        deviceControl
          .command(
            vm.taskModalId,
            {
              task_type: vm.modalData.task_type,
              data:
                vm.modalData.task_type === 'system-management'
                  ? vm.modalData.system_management
                  : vm.modalData.parameter_json
            },
            vm.token
          )
          .then((res) => {
            if (res.status <= 201 && res.data.status === 'success') {
              // vm.getDevice()
              vm.AlertsInfo({
                state: 'success',
                title: i18n.t('__success'),
                info: i18n.t('__sentOrder')
              })
              vm.bootstrap_modal.hide()
            } else {
              vm.validate = res.data.validate
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg.toString()
              })
            }
            vm.Loaded()
            vm.Alerted()
          })
      }
    }
  },
  created () {
    this.getDevice()
    this.getAllAgency()
  },
  mounted () {
    if (this.$route.query.search && this.$route.query.search !== this.filter) {
      this.search = this.$route.query.search
      this.filter = this.$route.query.search
    }
    if (this.$route.query.page && Number(this.$route.query.page) !== this.currentPage) {
      this.currentPage = this.$route.query.page || 1
      this.clickPaginationValue = this.$route.query.page || 1
    }
  }
}
</script>

<style  lang="scss">
</style>
