import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '@/lang/lang.js'
import { auth } from '@/http/api/auth.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
    isAlerting: false,
    isHideNav: false,
    isSystemManage: false,
    viewport: null,
    windowWidth: 0,
    language: [
      {
        name: '繁體中文',
        value: 'zh_TW'
      },
      {
        name: 'English',
        value: 'en'
      },
      {
        name: '日本語',
        value: 'ja'
      }
    ],
    breakpoints: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400
    },
    alerts: {
      state: null,
      icon: null,
      title: null,
      info: null
    },
    token: {
      headers: {
        Authorization: null
      }
    },
    userInfo: null
  },
  getters: {
    windowWidth: state => state.windowWidth,
    hasPermission: (state) => (permissionName) => {
      if (!state.userInfo || !state.userInfo.permissions) return false
      return state.userInfo.permissions.some(item => item.name === permissionName)
    },
    isSystemManage: (state, getters) => {
      return getters.hasPermission('system-manage')
    }
  },
  actions: {
    getUserInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          commit('getToken')
          auth.getUser(this.state.token).then((res) => {
            if (res.status <= 201) {
              commit('acceptUserInfo', res.data.data)
              const permissions = res.data.data.permissions
              permissions.forEach(item => {
                if (item.name === 'system-manage') {
                  commit('Permissions', true)
                }
              })
              if (res.data.data.locale) {
                i18n.locale = (res.data.data.locale === 'zh_TW') ? 'zh' : res.data.data.locale
              }
              resolve()
            }
          })
        }, 0)
      })
    }
  },
  mutations: {
    Permissions (state, isSystemManage) {
      state.isSystemManage = isSystemManage
    },
    Loading (state) {
      state.isLoading = true
    },
    Loaded (state) {
      state.isLoading = false
    },
    Alerted (state) {
      state.isAlerting = !state.isAlerting
    },
    HidedNav (state) {
      state.isHideNav = !state.isHideNav
    },
    AlertsInfo (state, data) {
      state.alerts.state = data.state
      state.alerts.icon = data.icon
      state.alerts.title = data.title
      state.alerts.info = data.info
    },
    getViewport (state) {
      const pseudoElementStyleContent = getComputedStyle(document.querySelector('body'), ':after').content
      // eslint-disable-next-line no-useless-escape
      state.viewport = pseudoElementStyleContent.replace(/\"/g, '')
    },
    setWindowWidth (state, value) {
      state.windowWidth = value
    },
    acceptUserInfo (state, data) {
      state.userInfo = data
    },
    getToken (state) {
      state.token.headers.Authorization = 'Bearer ' + localStorage.getItem('humetrics_token')
    }
  },
  modules: {
  }
})
