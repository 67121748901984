<template>
  <div class="nav">
    <div class="p-3 d-flex flex-row position-fixed top-0 end-0">
      <button
        class="btn btn-link navbar-icon nav-link d-none d-md-block"
        type="button"
        @click="toggleFullWindow"
      >
        <i
          class="bi"
          :class="
            is_fullWindow
              ? 'bi-arrows-angle-contract'
              : 'bi-arrows-angle-expand'
          "
        ></i>
      </button>
      <div class="dropdown user-info">
        <button
          class="btn btn-link navbar-icon nav-link"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          ref="dropdown"
        >
          {{ userInfo ? userInfo.name.substr(0, 1) : "H" }}
          <div class="user-info-icon"></div>
        </button>
        <div class="dropdown-menu dropdown-menu-end" style="width: 260px">
          <div class="px-4 py-3 text-center" v-if="userInfo">
            <div class="fw-medium">
              {{ userInfo.name }}
              <small class="text-dark">{{ userInfo.username }}</small>
            </div>
            <button
              type="button"
              class="btn btn-gray rounded-pill w-100 mt-3"
              @click="showLanguageModal"
            >
              {{ $t("__language") }}
            </button>
            <button
              type="button"
              class="btn btn-primary rounded-pill w-100 mt-3"
              @click="signOut"
            >
              {{ $t("__signOut") }}
            </button>
            <small class="pt-3 d-block"
              >© {{ new Date().getFullYear() + " " + $t("__HumetricsInc") }} All
              rights reserved.
            </small>
          </div>
        </div>
      </div>
      <button class="navbar-toggler text-dark" type="button" @click="HidedNav">
        <i class="bi bi-list"></i>
      </button>
    </div>
    <nav class="shadow-sm" :class="{ hide: isHideNav }">
      <router-link class="px-4 py-3 mb-2 d-block" :to="{ name: 'Dashboard' }">
        <img src="@/assets/images/Logo.svg" alt="Logo" width="126" />
      </router-link>
      <div class="nav-list border-top py-4">
        <ul class="nav flex-column">
          <li class="nav-item" v-if="isSystemManage === true">
            <router-link
              class="nav-link px-4 py-3"
              :to="{ name: 'Dashboard' }"
              >{{ $t("__dashboard") }}</router-link
            >
          </li>
          <li class="nav-item" v-if="hasPermission('agent.show')">
            <router-link class="nav-link px-4 py-3" :to="{ name: 'Agent' }">{{
              $t("__agentAccountManagement")
            }}</router-link>
          </li>
          <li class="nav-item" v-if="isSystemManage === true">
            <router-link class="nav-link px-4 py-3" :to="{ name: 'Agency' }">{{
              $t("__agencyManagement")
            }}</router-link>
          </li>
          <li class="nav-item" v-if="isSystemManage === true">
            <router-link class="nav-link px-4 py-3" :to="{ name: 'User' }">{{
              $t("__agencyAccountManagement")
            }}</router-link>
          </li>
          <li class="nav-item" v-if="isSystemManage === true">
            <router-link class="nav-link px-4 py-3" :to="{ name: 'Device' }">{{
              $t("__deviceCertificateManagement")
            }}</router-link>
          </li>
          <li class="nav-item" v-if="isSystemManage === true">
            <router-link
              class="nav-link px-4 py-3"
              :to="{ name: 'DeviceControl' }"
              >{{ $t("__deviceManagement") }}</router-link
            >
          </li>
          <li class="nav-item" v-if="hasPermission('chiline.show')">
            <router-link class="nav-link px-4 py-3" :to="{ name: 'Chiline' }">{{
                $t("__chilineManagement")
              }}</router-link>
          </li>
          <li class="nav-item" v-if="hasPermission('fora.show')">
            <router-link class="nav-link px-4 py-3" :to="{ name: 'Fora' }">{{
                $t("__foraManagement")
              }}</router-link>
          </li>
          <!-- <li class="nav-item" v-if="isSystemManage">
            <router-link
              class="nav-link px-4 py-3"
              :to="{ name: 'DeviceReport' }"
              >{{ $t("__errorReport") }}</router-link
            >
          </li> -->
          <li class="nav-item" v-if="hasPermission('connection.show')">
            <router-link class="nav-link px-4 py-3" :to="{ name: 'Contact' }">{{
              $t("__contactUs")
            }}</router-link>
          </li>

          <li class="nav-item" v-if="hasPermission('announcements.show')">
            <router-link class="nav-link px-4 py-3" :to="{ name: 'Announcements' }">{{
                $t("__announcements")
              }}</router-link>
          </li>

          <li class="nav-item" v-if="hasPermission('chats.show')">
            <router-link class="nav-link px-4 py-3" :to="{ name: 'Chats' }">{{
                $t("__chats")
              }}</router-link>
          </li>
        </ul>
      </div>
    </nav>
    <transition name="fade">
      <div
        @click="HidedNav"
        class="nav-background"
        v-show="viewport < 992 && !isHideNav"
      ></div>
    </transition>
    <div
      class="modal fade"
      id="LanguageModal"
      tabindex="-1"
      aria-hidden="true"
      ref="languageModal"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("__language") }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-check mb-3">
              <input
                class="form-check-input"
                type="radio"
                name="language"
                id="language-zh"
                v-model="language"
                value="zh"
              />
              <label class="form-check-label" for="language-zh">
                繁體中文
              </label>
            </div>
            <div class="form-check mb-3">
              <input
                class="form-check-input"
                type="radio"
                name="language"
                id="language-en"
                v-model="language"
                value="en"
              />
              <label class="form-check-label" for="language-en">
                English
              </label>
            </div>
            <div class="form-check mb-3">
              <input
                class="form-check-input"
                type="radio"
                name="language"
                id="language-ja"
                v-model="language"
                value="ja"
              />
              <label class="form-check-label" for="language-ja">
                日本語
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button
              @click="changeLanguage"
              type="button"
              class="btn btn-primary"
            >
              {{ $t("__changeDefaultLanguage") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import i18n from "@/lang/lang.js";
import { auth } from "@/http/api/auth.js";
import { Dropdown, Modal } from "bootstrap";
import {mapState, mapMutations, mapActions, mapGetters} from "vuex";

export default {
  name: "Navbar",
  data() {
    return {
      is_fullWindow: false,
      is_invalid: false,
      feedback_message: null,
      bootstrap_dropdown: null,
      bootstrap_modal: null,
      language: null,
    };
  },
  computed: {
    ...mapState(["viewport", "token", "isHideNav", "userInfo"]),
    ...mapGetters(['hasPermission', 'isSystemManage'])
  },
  watch: {
    viewport(data) {
      if (Number(data) < 992 && !this.isHideNav) {
        this.HidedNav();
      } else if (Number(data) > 992 && this.isHideNav) {
        this.HidedNav();
      }
    },
    userInfo() {
      const vm = this;
      if (vm.userInfo.status !== 1) { // if user disable, then directly signout
        vm.signOut()
      }
      window.document.title =
        i18n.t(vm.$route.meta.title) + " | " + i18n.t("__humetrics");
      document.documentElement.lang = i18n.locale;
      vm.language = i18n.locale;
    },
  },
  methods: {
    ...mapMutations(["Loading", "Loaded", "AlertsInfo", "Alerted", "HidedNav"]),
    ...mapActions(["getUserInfo"]),
    signOut() {
      localStorage.removeItem("humetrics_token");
      this.$router.push({ name: "Login" });
    },
    fullWindow() {
      const docElm = document.documentElement;
      if (docElm.requestFullscreen) {
        // W3C
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        // FireFox
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        // Chrome
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        // IE11
        docElm.msRequestFullscreen();
      }
    },
    cancelFullWindow() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    toggleFullWindow() {
      const vm = this;
      vm.is_fullWindow = !vm.is_fullWindow;
      vm.is_fullWindow ? vm.fullWindow() : vm.cancelFullWindow();
    },
    showLanguageModal() {
      const vm = this;
      vm.bootstrap_modal = new Modal(vm.$refs.languageModal);
      vm.bootstrap_modal.show();
    },
    changeLanguage() {
      const vm = this;
      auth.updateUser({ locale: vm.language }, vm.token).then((res) => {
        if (res.status <= 201 && res.data.status === "success") {
          vm.AlertsInfo({
            state: "success",
            title: i18n.t("__success"),
            info: i18n.t("__editSuccess"),
          });
          i18n.locale = vm.language;
          vm.bootstrap_modal.hide();
        } else {
          vm.AlertsInfo({
            state: "error",
            title: i18n.t("__error"),
            info: res.data.errMsg.toString(),
          });
        }
        vm.Alerted();
        vm.Loaded();
      });
    },
  },
  created() {
    const vm = this;
    vm.getUserInfo();
  },
  mounted() {
    const vm = this;
    vm.bootstrap_dropdown = new Dropdown(vm.$refs.dropdown);
  },
};
</script>

<style lang="scss">
.nav {
  .position-fixed {
    z-index: 997;
  }
  &-background {
    background-color: rgba(#000, 0.3);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    transition: transform 0.3s ease-in-out;
    z-index: 998;
  }
  nav {
    background-color: $white;
    height: 100vh;
    width: 220px;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    transition: transform 0.3s ease-in-out;
    z-index: 999;
    &.hide {
      transform: translateX(-100%);
    }
  }
  a,
  &-icon {
    color: $dark;
  }
  .nav-link {
    color: $dark;
  }
  .nav {
    &-list {
      height: calc(100% - 69px);
      overflow-y: auto;
      .nav-link {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 15px;
          height: 50px;
          transform: translateY(-50%);
          background-image: url("~@/assets/images/NavAtive.svg");
          background-repeat: no-repeat;
          opacity: 0;
        }
        &.active {
          color: $primary;
          &::after {
            opacity: 1;
          }
        }
        &:hover,
        &:focus {
          color: $primary;
        }
        &.btn {
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
  .user-info {
    > .btn {
      color: $white;
      position: relative;
      padding: 6px 10px;
      z-index: 1;
    }
    &-icon {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 0;
        padding-top: 100%;
        background: $linear-gradient;
        border-radius: 100%;
      }
    }
  }
}
</style>
